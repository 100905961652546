import {
  Permission,
  PermissionActionScope,
  PermissionResource,
  PermissionRessourceAction,
  Role,
} from '@api';

export const getRole = (permissions: Permission[]): Role => {
  const admin = hasPermission(
    PermissionResource.Group,
    PermissionRessourceAction.CanModifyData,
    PermissionActionScope.OnlyInManagedGroups,
    permissions,
  );
  const curriculumManager = hasPermission(
    PermissionResource.Course,
    PermissionRessourceAction.CanModifyData,
    PermissionActionScope.OnlyInManagedGroups,
    permissions,
  );
  const dataAnalyst = hasPermission(
    PermissionResource.Report,
    PermissionRessourceAction.CanExportData,
    PermissionActionScope.OnlyInManagedGroups,
    permissions,
  );
  const simulatorAdmin = hasPermission(
    PermissionResource.Simulator,
    PermissionRessourceAction.CanReadData,
    PermissionActionScope.OnlyInManagedGroups,
    permissions,
  );
  return admin || curriculumManager || dataAnalyst || simulatorAdmin
    ? 'Admin'
    : 'User';
};

const hasPermission = (
  resource: PermissionResource,
  action: PermissionRessourceAction,
  scope: PermissionActionScope,
  permissions: Permission[],
): boolean =>
  permissions.some((permission) =>
    permissionComparator(resource, action, scope, permission),
  );

const permissionComparator = (
  permissionResource: PermissionResource,
  permissionResourceAction: PermissionRessourceAction,
  permissionActionScope: PermissionActionScope,
  permission: Permission,
) => {
  const neededPermission = <Permission>{
    permissionResource,
    permissionResourceAction,
    permissionActionScope,
  };
  return (
    neededPermission.permissionResource === permission.permissionResource &&
    neededPermission.permissionResourceAction ===
      permission.permissionResourceAction &&
    neededPermission.permissionActionScope === permission.permissionActionScope
  );
};
