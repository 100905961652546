export const secondsToTimeString = (value: number): string => {
  if (isNaN(value)) {
    return '00:00';
  }
  const seconds = Math.round(value % 60);
  const minutes = Math.floor(value / 60);
  return (
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  );
};
