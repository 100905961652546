import { effect, inject } from '@angular/core';
import { getState, signalStoreFeature, withHooks } from '@ngrx/signals';
import { AppStorageService } from '@services';

export function withSyncToStorage(keys: string[], storageKey?: string) {
  return signalStoreFeature(
    withHooks({
      onInit(store: any) {
        const appStorageService = inject(AppStorageService);
        effect(() => {
          if (storageKey && getState(store)) {
            const storage: any = {};
            keys.forEach((key) => {
              storage[key] = store[key]();
            });
            appStorageService.setItem(storageKey, storage);
          } else {
            keys.forEach((key) => {
              appStorageService.setItem(key, store[key]());
            });
          }
        });
      },
    }),
  );
}
