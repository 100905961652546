import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOption } from '@models';
import { SceneStore, UserStore } from '@stores';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router = inject(Router);
  private sceneStore = inject(SceneStore);
  private userStore = inject(UserStore);

  navigation = signal<NavigationOption>('scenes');

  navigationList = computed((): NavigationOption[] => {
    const startPage = this.userStore.appSettings.startPage();
    return [
      ...(this.adminNavigationList().some((item) => item === startPage)
        ? []
        : [startPage]),
      ...[
        'dashboard',
        'scenes',
        ...(this.sceneStore.report().id ? ['evaluation'] : []),
      ].filter((item) => startPage !== item),
    ] as NavigationOption[];
  });

  adminNavigationList = computed((): NavigationOption[] => ['uploads']);

  navigate(target?: string) {
    if (target && this.isNavigationItem(target)) {
      this.navigation.set(target);
    }
    this.router.navigate([this.navigation()]);
  }

  private isNavigationItem(target: string): target is NavigationOption {
    return [
      'scenes',
      'dashboard',
      'courses',
      'runner',
      'evaluation',
      'uploads',
    ].includes(target);
  }
}
