import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpClient } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { SceneAndMultiScene } from '@models';
import {
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { LocalizationService, XmlParserService } from '@services';
import { withSyncToStorage } from './withSyncToStorage.feature';
import { AppStore } from '@stores';

interface Simulator {
  identifier: string;
  nameLanguagekey: string;
  imageBlobUrl: string;
  sceneDataPath: string;
}

interface SceneSection {
  identifier: string;
  name: string;
  scenes: SceneAndMultiScene[];
}

interface Evaluation {
  component: string;
  inputs: { [key: string]: string };
}

interface DataState {
  simulator: Simulator;
  sceneSections: SceneSection[];
  evaluation: Evaluation[];
}

const initialState: DataState = {
  simulator: {} as Simulator,
  sceneSections: [],
  evaluation: [],
};

export const DataStore = signalStore(
  { providedIn: 'root' },
  withDevtools('data'),
  withState(initialState),
  withSyncToStorage(['simulator', 'sceneSections']),
  withComputed(
    (
      { simulator, sceneSections },
      localizationService = inject(LocalizationService),
      appStore = inject(AppStore),
    ) => ({
      selectedScene: computed(
        () =>
          sceneSections().flatMap((section) =>
            section.scenes.filter(
              (scene) =>
                scene.identifier === appStore.selectedScene().sceneGuid,
            ),
          )[0],
      ),
      localizedSimulator: computed(() => ({
        ...simulator(),
        name: localizationService.getTranslation(simulator().nameLanguagekey),
      })),
      licensedScenes: computed(() => ({
        ...simulator(),
        sceneSections: sceneSections()
          .map((section) => ({
            ...section,
            scenes: section.scenes,
            /*             userStore
              .licensedScenes()
              .some((licensedScene) => licensedScene === scene.identifier),
          ), */
          }))
          .filter((sceneSection) => sceneSection.scenes.length > 0),
      })),
      languageKeys: computed(() => {
        let newScenes = {};
        sceneSections().forEach((sceneSection) =>
          sceneSection.scenes.forEach((scene) => {
            newScenes = {
              ...newScenes,
              [scene.identifier]: {
                [scene.nameLanguageKey]: scene.name,
                [scene.sceneSectionNameLanguageKey]: scene.sceneSectionName,
              },
            };
          }),
        );
        return newScenes as { [key: string]: { [key: string]: string } };
      }),
    }),
  ),
  withMethods((store, http = inject(HttpClient)) => ({
    setSimulator: (simulator: Simulator) =>
      updateState(store, '[Data] Set simulator', (state: DataState) => ({
        simulator,
      })),
    setSceneSections: (sceneSections: SceneSection[]) =>
      updateState(store, '[Data] Set sceneSections', (state: DataState) => ({
        sceneSections,
      })),
    setEvaluation: (evaluation: Evaluation[]) =>
      updateState(store, '[AppData] Set evaluation', (state: DataState) => ({
        evaluation,
      })),
  })),
  withHooks({
    onInit(store, xmlParserService = inject(XmlParserService)) {
      fetch('config.json')
        .then((response) => response.json())
        .then((config) => {
          store.setSimulator(config.simulator);
          store.setEvaluation(config.evaluation);
        })
        .catch((error) => {
          console.error('Error loading config:', error);
        })
        .then(() => {
          fetch(store.simulator().sceneDataPath)
            .then((response) => response.text())
            .then((xmlData) => {
              const sections = xmlParserService.xmlSceneParser(xmlData);
              store.setSceneSections(sections);
            })
            .catch((error) => {
              console.error('Error loading config:', error);
            });
        });
    },
  }),
);
