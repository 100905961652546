import { ReportUpload } from '@api';

export async function encryptReport(
  uploadObject: ReportUpload,
  keyString: string,
) {
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const keyMaterial = new TextEncoder().encode(keyString);
  const value = new TextEncoder().encode(JSON.stringify(uploadObject));

  const key = await crypto.subtle.importKey(
    'raw',
    keyMaterial,
    'AES-CBC',
    false,
    ['encrypt'],
  );

  const encryptedArrayBuffer = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv: iv,
    },
    key,
    value,
  );

  const encrypted = btoa(
    String.fromCharCode(...new Uint8Array(encryptedArrayBuffer)),
  );
  const ivBase64 = btoa(String.fromCharCode(...iv));

  return { encrypted, iv: ivBase64 };
}
