async function getKey() {
  const generateStringFromNumber = (): string => {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const resultLength = 32;
    const year = new Date().getFullYear();
    let seed = Math.floor(year / 100);
    const random = (): number => {
      seed = (seed * 9301 + 49297) % 233280;
      return seed / 233280;
    };
    let result = '';
    for (let i = 0; i < resultLength; i++) {
      const randomIndex = Math.floor(random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  };
  const generatedString = generateStringFromNumber();
  const keyMaterial = new TextEncoder().encode(generatedString);
  return await crypto.subtle.importKey(
    'raw',
    keyMaterial,
    { name: 'AES-GCM' },
    false,
    ['encrypt', 'decrypt'],
  );
}

export async function encryptObject(object: any) {
  const key = await getKey();
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const data = new TextEncoder().encode(JSON.stringify(object));
  const ciphertext = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    data,
  );
  return { ciphertext: new Uint8Array(ciphertext), iv };
}

export async function decryptObject(encryptedData: any) {
  if (!encryptedData) {
    return null;
  }
  const key = await getKey();
  const { ciphertext, iv } = encryptedData;
  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    ciphertext,
  );
  const jsonString = new TextDecoder().decode(decrypted);
  return JSON.parse(jsonString);
}
