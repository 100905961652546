import { ReportUpload } from '@api';

export const fakeReport = {
  actualSceneIdentifier: '6c21c6e3-489c-5da6-ac38-f2a3c5715a89',
  simulatorIdentifier: 'fe0cfb8e-6ffd-47b5-94a3-cd1ae02597d0',
  simulatorVersion: '1.0.0',
  userInterfaceVersion: '0.0',
  endDate: '2024-12-09T11:20:03.1990000+01:00',
  id: '0f60439e-ad8d-48c1-ab9d-af8d071bab0b',
  score: 0,
  maxScore: 0,
  simulationPreferences: [],
  reportSections: [
    {
      sectionIdentifier: '6ffc2cc1-4023-4bed-9516-c3213acaef80',
      score: 0,
      maxScore: 0,
      descriptionLanguageKey: 'Metadata.ReportSections.Metrics.Description',
      reportItems: [
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MinSpeedWhileWithdrawing.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: '066027fc-4e91-4980-bd5f-2e8f3f8dd263',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MinSpeedWhileWithdrawing.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.AverageSpeedWhileWithdrawing.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: '56e476de-2b4b-4881-878a-ad1a33725317',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.AverageSpeedWhileWithdrawing.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MaxSpeedWhileWithdrawing.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: 'efbc0cfa-4fde-4be1-8242-af8f46585d86',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MaxSpeedWhileWithdrawing.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MinSpeedDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: 'd7eeff0b-c0f2-4167-bdb3-0afc858dbf83',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MinSpeedDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.AverageSpeedDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: 'd4fd5d3a-7fb8-46f4-ab63-2012787a1121',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.AverageSpeedDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MaxSpeedDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'CentimeterPerSecond',
          value: '0',
          id: '8de2f993-1903-4f8f-a028-b2f5b363add9',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MaxSpeedDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MinAngleDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '6373e93a-44cb-4a76-b3b7-44255357193d',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MinAngleDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.AverageAngleDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '156d0bf7-0e40-4e1d-82a8-20e8f0354899',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.AverageAngleDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MaxAngleDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '5247e360-e23c-4265-a11e-90a45d3192f9',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.MaxAngleDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MinAngleWhileRotatingLever.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '53144b60-9da0-421f-a9ec-5f47884cb254',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.MinAngleWhileRotatingLever.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.AverageAngleWhileRotatingLever.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '0bc84440-5eb0-40ad-8e43-63a21c864b94',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.AverageAngleWhileRotatingLever.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.MaxAngleWhileRotatingLever.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Angle',
          value: '30',
          id: '0d47c779-4ea8-4b39-bcf5-42c258e54f5a',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.MaxAngleWhileRotatingLever.Name',
        },
      ],
      id: '00000000-0000-0000-0000-000000000000',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Metadata.ReportSections.Metrics.Name',
    },
    {
      sectionIdentifier: 'fb3def50-efd9-45ea-b327-773ea8f8df5a',
      score: 0,
      maxScore: 0,
      descriptionLanguageKey:
        'Metadata.ReportSections.Questionnaire.Description',
      reportItems: [
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.CalculateDepthRetries.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Integer',
          value: '0',
          id: '5be95486-820c-4e13-916c-7df06477de89',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.CalculateDepthRetries.Name',
        },
      ],
      id: '00000000-0000-0000-0000-000000000000',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Metadata.ReportSections.Questionnaire.Name',
    },
    {
      sectionIdentifier: '2eb5b63d-cd5a-4df7-9fee-6fb7c7f91048',
      score: 0,
      maxScore: 0,
      descriptionLanguageKey: 'Metadata.ReportSections.Errors.Description',
      reportItems: [
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.ExcessiveSutureTension.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Centimeter',
          value: '0',
          id: '0484fd24-d0d4-4910-ac54-d3d39e0ea013',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.ExcessiveSutureTension.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.ExcessiveYellowGreenTension.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Centimeter',
          value: '0',
          id: '577e5dcb-d080-457c-8cad-0b622bc51818',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.ExcessiveYellowGreenTension.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.ReinsertionCountDuringRetraction.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Integer',
          value: '0',
          id: '4d26bbcb-44f0-4aa4-8aff-5b774e004afa',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.ReinsertionCountDuringRetraction.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.ReinsertionCountWhileWithdrawing.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Integer',
          value: '0',
          id: '422a4bbd-97f2-49eb-84ac-bb2d96d9fbbb',
          sortId: 0,
          isVisible: true,
          nameLanguageKey:
            'Metadata.ReportItem.ReinsertionCountWhileWithdrawing.Name',
        },
        {
          bestIs: 'None',
          descriptionLanguageKey:
            'Metadata.ReportItem.AnchorAdvancedIntoArtery.Description',
          displayMode: 'Default',
          maxScore: 0,
          maxValue: 0,
          minValue: 0,
          noScore: true,
          reportItemTags: [],
          reportMode: 'Required',
          score: 0,
          unit: 'Centimeter',
          value: '0',
          id: 'e8c6f55c-2bfa-4491-83ce-56cf1ed5f770',
          sortId: 0,
          isVisible: true,
          nameLanguageKey: 'Metadata.ReportItem.AnchorAdvancedIntoArtery.Name',
        },
      ],
      id: '00000000-0000-0000-0000-000000000000',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Metadata.ReportSections.Errors.Name',
    },
  ],
  sceneIdentifier: '6c21c6e3-489c-5da6-ac38-f2a3c5715a89',
  sceneSectionLanguageKey: 'Metadata.Sections.Hardware.Name',
  startDate: '2024-12-09T11:19:52.8680000+01:00',
  status: 'Completed',
  userTasks: [
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.PrepareSetup.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '70238376-019b-4d06-9faf-fd3342d27fe4',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.PrepareSetup.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.AdvanceClosureDevice.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '8c5ed4c9-ccfc-4442-a1d2-376a2c6d299b',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.DetermineDepth.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RetractDepthLocator.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '2ddff543-6b4e-4473-9c78-adffdcb347fa',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RetractDepthLocator.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.ConfirmDepth.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'da87a9dd-158e-4254-8f83-a2edc8b053bd',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.ConfirmDepth.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RemoveDepthLocator.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'c2b0f9ed-cbd0-433e-a722-9c81d4c9d2e5',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RemoveDepthLocator.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.CalculateDepth.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'bdb17597-2a8a-4650-b539-2d00392f05ac',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.CalculateDepth.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.StartBoreProcedure.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '286f0418-41b1-4948-94a2-b938a5553022',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.StartBoreProcedure.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.CompleteBoreProcedure.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '5e0a8237-1b46-47a2-bec8-9584bc214d00',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.CompleteBoreProcedure.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey:
        'Case.Manta.AssembleSheathAndIntroducer.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '5ac406a0-425d-4915-8df0-edd84962dc40',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.AssembleSheathAndIntroducer.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.InsertSheath.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '7da9cd27-d27f-47bc-a43c-a590a7ee278e',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.InsertSheath.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RemoveIntroducer.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '62152958-c8c6-40cf-ab4d-2baa5e86bce2',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RemoveIntroducer.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey:
        'Case.Manta.ConfirmPositionOfBypassTube.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '0e70e34b-5f8c-4d42-9f69-20c08212b99f',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.ConfirmPositionOfBypassTube.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.InsertBypassTube.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'bf75124e-0d5b-46b8-920d-ccd458ca6dbd',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.InsertBypassTube.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.AdvanceClosureDevice.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'd2537293-6584-4af5-a280-86d49c04c3ea',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.AdvanceClosureDevice.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.ConfirmCorrectAssembly.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '85d3be3f-4968-44f4-b8b2-ae0b173ddc1e',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.ConfirmCorrectAssembly.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RetractDeviceAt45Degrees.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '909acdcc-a363-4a34-984b-aabf5082a50c',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RetractDeviceAt45Degrees.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RotateDeploymentLever.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '73f48eac-33b4-4f2f-b2a0-d6f04460161f',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RotateDeploymentLever.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.WithdrawDevice.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '0d966606-2ceb-417d-b85f-85ea5a858fea',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.WithdrawDevice.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey:
        'Case.Manta.AdvanceLockAdvancementTube.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '2823ccbe-4550-4ab6-8227-912fce8e7cfa',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.AdvanceLockAdvancementTube.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RetractDeviceUntilClick.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'd44635a7-8279-4f6e-a482-4136fff592a3',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RetractDeviceUntilClick.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey:
        'Case.Manta.RetractLockAdvancementTube.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '28f32313-74ef-490c-b55a-b408ffc68c41',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RetractLockAdvancementTube.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.ConfirmHemostasis.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'ff8ccf76-5371-4ac1-87ff-222809b75d48',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.ConfirmHemostasis.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.RemoveGuideWire.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: '638c8bee-320e-4d1e-8b95-4aa8f7f8e6b1',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.RemoveGuideWire.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.CutSuture.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'c39f2148-e070-4134-9948-27e49fdd5ad6',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.CutSuture.Name',
    },
    {
      taskType: 'UserTask',
      isRequired: true,
      descriptionLanguageKey: 'Case.Manta.ResetDevice.Description',
      state: 'Completed',
      value: '0',
      subTasks: [],
      taskEvents: [],
      id: 'f7b2b837-2173-4433-b157-b3d30d9859bb',
      sortId: 0,
      isVisible: true,
      nameLanguageKey: 'Case.Manta.ResetDevice.Name',
    },
  ],
  timeZoneString: '(GMT+01:00) Local Time',
  userSession: '20324faa-f353-4ce2-855a-c5b325070ade',
  screenshots: [],
  movies: [],
  reportAttachments: [],
};

export const fakeReports = [
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: '',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: '55cac3ec-4e57-487c-9d62-8147c5f87c8e',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: '7d8b3ab7-25e4-4b3c-9ccb-815da38e92b8',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: '81a434a1-cb58-4c87-829a-0306b7b286df',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: '6bae9d25-f3af-47be-a331-163adcbcb751',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: 'e08ff019-cf47-48d0-9119-cf484575dc49',
  },
  {
    state: 'completed',
    user: 'Webster Launchello',
    data: fakeReport as unknown as ReportUpload,
    id: 'a6853e47-5f75-4446-b16d-e1745958f1ef',
  },
];
