import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpClient } from '@angular/common/http';
import { computed, effect, inject } from '@angular/core';
import { License } from '@api';
import { NavigationOption, User } from '@models';
import { tapResponse } from '@ngrx/operators';
import {
  getState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, pipe } from 'rxjs';
import { UsersStorageService } from '../authentication/users-storage.service';
import { withSyncToStorage } from './withSyncToStorage.feature';

const initialState: User = {
  id: 0,
  guid: null,
  firstName: '',
  lastName: '',
  image: null,
  email: '',
  language: '',
  role: 'User',
  appSettings: {
    startPage: 'scenes',
  },
  licensedScenes: [],
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialState),
  withSyncToStorage(
    [
      'id',
      'firstName',
      'lastName',
      'image',
      'email',
      'language',
      'appSettings',
    ],
    'user',
  ),
  withComputed(({ firstName, lastName, email, role }) => ({
    isAuthenticated: computed(() => !!email()),
    isAdmin: computed(() => role() === 'Admin'),
    fullName: computed(() => (firstName() ?? '') + ' ' + (lastName() ?? '')),
    initals: computed(
      () =>
        (
          (firstName() ? firstName()[0] : '') +
          (lastName() ? lastName()[0] : '')
        ).toUpperCase() ?? '',
    ),
  })),
  withMethods((store, http = inject(HttpClient)) => ({
    updateUserData(user: Partial<User>): void {
      updateState(store, '[User] Update user data', user);
    },
    setImageBlob(image: Blob): void {
      updateState(store, '[User] Set image blob', { image });
    },
    setRole(role: User['role']): void {
      updateState(store, '[User] Set role', { role });
    },
    setStartPage(startPage: NavigationOption): void {
      updateState(store, '[User] Set start page', {
        appSettings: { ...store.appSettings, startPage },
      });
    },
    clearUserData(): void {
      updateState(store, '[User] Clear data', initialState);
    },
    loadLicences: rxMethod(
      pipe(
        concatMap(() => http.get<License[]>('/api/users/me/license')),
        tapResponse({
          next: (licenses) =>
            updateState(
              store,
              '[App data] License update',
              (state: User): User => ({
                ...state,
                licensedScenes: licenses.map(
                  (license) => license.sceneOverview.identifier,
                ),
              }),
            ),
          error: console.error,
        }),
      ),
    ),
  })),
  withHooks({
    onInit(store: any, usersStorageService = inject(UsersStorageService)) {
      effect(() => {
        const user = getState(store) as User;
        if (user.email.length > 0) {
          usersStorageService.saveUser(user);
        }
      });
    },
  }),
);
