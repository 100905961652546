import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { fakeReports } from 'app/fake/fake-report';
import { DataStore } from '@stores';
import { SceneReport, UploadState } from '@models';
import { ReportUpload } from '@api';
import { withSyncToStorage } from './withSyncToStorage.feature';

interface ReportsState {
  reports: SceneReport[];
}

const initialState: ReportsState = {
  reports: fakeReports as unknown as SceneReport[],
};

export const ReportsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('reports'),
  withState(initialState),
  withSyncToStorage(['reports']),
  withComputed(({ reports }, dataStore = inject(DataStore)) => ({
    states: computed(() => ({
      pending: reports().filter(
        (report) => report.state === UploadState.pending,
      ).length,
      completed: reports().filter(
        (report) => report.state === UploadState.completed,
      ).length,
      failed: reports().filter((report) => report.state === UploadState.failed)
        .length,
    })),
    localizedReports: computed((): SceneReport[] => {
      return reports().map((report) => {
        const sceneSection = dataStore
          .sceneSections()
          .find((sceneSection) =>
            sceneSection.scenes.find(
              (scene) => scene.identifier === report.data.sceneIdentifier,
            ),
          );
        return {
          data: {
            ...report.data,
            name: sceneSection?.scenes[0]?.name,
            sectionName: sceneSection?.name,
          },
          user: report.user,
          state: report.state,
        } as SceneReport;
      });
    }),
  })),
  withMethods((store) => {
    return {
      addReport(report: Partial<ReportUpload>, user: string): void {
        updateState(store, '[Reports] Add report', (state: ReportsState) => ({
          ...state,
          reports: [
            {
              id: crypto.randomUUID(),
              user,
              state: UploadState.pending,
              data: report as SceneReport['data'],
            },
            ...state.reports,
          ],
        }));
      },
      changeState(id: string, uploadState: UploadState): void {
        updateState(
          store,
          '[Reports] Change report state',
          (state: ReportsState) => ({
            ...state,
            reports: state.reports
              .reverse()
              .map((report) =>
                report.id === id ? { ...report, state: uploadState } : report,
              ),
          }),
        );
      },
      setReports(reports: SceneReport[]): void {
        updateState(store, '[Reports] Set reports', (state: ReportsState) => ({
          ...state,
          reports,
        }));
      },
    };
  }),
);
