import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackBar = inject(MatSnackBar);

  showSnackBar(data: { message: string; action?: string; duration?: number }) {
    return this.snackBar
      .open(data.message, data.action ?? undefined, {
        duration: data.duration ?? 3000,
        panelClass: 'snackbar',
      })
      .onAction();
  }
}
