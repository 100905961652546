export interface GroupPermission {
  groupId: number;
  permissions: Permission[];
}

export interface Permission {
  permissionResource: PermissionResource;
  permissionResourceAction: PermissionRessourceAction;
  permissionActionScope: PermissionActionScope;
}

export type Role = 'Guest' | 'User' | 'Admin';

export enum PermissionResource {
  Simulator = 1,
  User = 2,
  Group = 3,
  Report = 4,
  Leaderboard = 5,
  Course = 6,
  Certificate = 7,
}

export enum PermissionRessourceAction {
  AssignToGroup = 1,
  CanReadData = 2,
  CanModifyData = 3,
  CanAddUsers = 4,
  CanRemoveUsers = 5,
  CanRemove = 6,
  CanInviteUsers = 7,
  CanModifyIsVisiblePolicy = 8,
  CanModifySimJoinPolicy = 9,
  CanModifyUserJoinPolicy = 10,
  CanExportData = 11,
  CanEditRoleAssignments = 12,
  CanCreate = 13,
  CanModifyIsMainVisiblePolicy = 14,
  CanModifyUserMainGroup = 15,
}

export enum PermissionActionScope {
  NotRequired = 0,
  All = 1,
  Unassigned = 2,
  OnlyInManagedGroups = 3,
  OnlyWithGroupOwnership = 4,
  OnlyInMyGroups = 5,
}
