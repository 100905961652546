import { inject, Injectable } from '@angular/core';
import { ReportUpload } from '@api';
import { base64ToBlob } from '@functions';
import { WebAppState } from '@models';
import { UploadService } from '@services';
import { AppStore, SceneStore } from '@stores';
import { Router } from '@angular/router';

let webAppState: WebAppState | null = 'inactive';
let sentImage: { id: string; blob: Blob } | null = null;
let sentVideo: { id: string; blob: Blob } | null = null;
let sentReport: ReportUpload | null = null;

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private appStore = inject(AppStore);
  private router = inject(Router);
  private sceneStore = inject(SceneStore);
  private uploadService = inject(UploadService);

  constructor() {
    (window as any)['setState'] = this.getState;
    (window as any)['sendImage'] = this.getImage;
    (window as any)['sendVideo'] = this.getVideo;
    (window as any)['sendReport'] = this.getReport;
    (window as any)['sendString'] = this.getString;

    setInterval(() => {
      if (sentImage) {
        const screenshot = {
          ...sentImage,
          fileName: 'screenshot.png',
          fileGeneratedName: '',
          groupLanguageKey: 'groupLangugeKey',
          descriptionLanguageKey: 'descriptionLanguageKey',
          nameLanguageKey: 'nameLanguageKey',
          sortId: 0,
        };
        this.sceneStore.addScreenshot(screenshot);
        sentImage = null;
      }
      if (sentVideo) {
        const video = {
          ...sentVideo,
          fileName: 'video.mp4',
          fileGeneratedName: '',
          nameLanguageKey: 'nameLanguageKey',
        };
        this.sceneStore.addVideo(video);
        sentVideo = null;
      }
      if (sentReport) {
        this.sceneStore.setReport(sentReport);
        this.uploadService.upladReport();
        this.router.navigate(['/evaluation']);
        sentReport = null;
      }
      if (webAppState) {
        this.appStore.setWebAppState(webAppState);
        webAppState = null;
      }
    }, 500);
  }

  async startScene(sceneGuid: string, originalMultiSceneGuid?: string) {
    // 'a731d635-4faf-4e64-9f1b-9206a55835e7'
    /*     const sceneToStart = {
      SceneGuid: sceneGuid,
      OriginalMultiSceneGuid: originalMultiSceneGuid,
    }; */
    const sceneToStart = {
      SceneGuid: sceneGuid,
    };
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Start Scene: ', sceneToStart);

    const waitForIframe = async () => {
      return new Promise((resolve, reject) => {
        const iframe = document.getElementById('unityIframe') as any;
        if (!iframe) {
          return reject('Iframe not found');
        }
        const checkIframeReady = () => {
          if (iframe.contentWindow && iframe.contentWindow.unityInstance) {
            resolve(iframe);
          } else {
            setTimeout(checkIframeReady, 300);
          }
        };
        checkIframeReady();
      });
    };

    try {
      const iframe = await waitForIframe();
      (iframe as any).contentWindow.unityInstance.SendMessage(
        'WebLauncherConnector',
        'StartScene',
        JSON.stringify(sceneToStart),
      );
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error starting scene:', error);
    }
  }

  getState(state: string) {
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Get state:', state);
    webAppState = state.toLowerCase() as WebAppState;
  }

  getString(message: string) {
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Get string:', message);
  }

  getImage(id: string, blob: any) {
    sentImage = { id, blob: base64ToBlob(blob, 'image/jpg') };
  }

  getVideo(id: string, blob: any) {
    sentVideo = { id, blob: base64ToBlob(blob, 'video/mp4') };
  }

  getReport(newReport: any) {
    const convertToCamelCase = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map((v) => convertToCamelCase(v));
      } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
          const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
          result[camelCaseKey] = convertToCamelCase(obj[key]);
          return result;
        }, {});
      }
      return obj;
    };
    sentReport = convertToCamelCase(JSON.parse(newReport));
  }
}
