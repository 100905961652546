import { Environment } from './environment.interfaces';

export const environment: Environment = {
  production: false,
  sandbox: true,
  backendUrl: 'https://appdevelop.virtamed.cloud',
  identityUrl: 'https://identityserverdevelop.virtamed.cloud',
  version: 'Develop 0.8.0',
  simulatorIdentifier: '7e38a133-d23c-4822-8863-c466163cbcbf',
  adminGroupId: 10258,
};
