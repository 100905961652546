import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ReportUpload } from '@api';
import { environment } from '@environment';
import { createId, encryptReport } from '@functions';
import { FileUpload } from '@models';
import { DataStore, SceneStore } from '@stores';
import { EMPTY, catchError, forkJoin, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private dataStore = inject(DataStore);
  private http = inject(HttpClient);
  private router = inject(Router);
  private sceneStore = inject(SceneStore);

  neverEndingContext = '7c7d351f-0967-46ef-8152-a0e2ed674bd3';

  private baseUrl = environment.backendUrl;
  private videoChunks: Blob[] = [];
  private blockIds: string[] = [];
  private chunkCount = 0;
  private uploadObject = {} as ReportUpload;
  private fileUploadProgress = signal(0);

  upladReport() {
    this.createData();
    this.http
      .post<string>(`/api/user/reports/createcontext`, {
        groupId: environment.adminGroupId,
        machineIdentifier: environment.simulatorIdentifier,
        sceneIdentifier: this.uploadObject.sceneIdentifier,
      })
      .subscribe((reportContext: string) => {
        this.submitData(reportContext);
      });
  }

  createData() {
    this.uploadObject = {
      ...({} as ReportUpload),
      ...this.sceneStore.reportUpload(),
      sceneNameLanguageKey: this.dataStore.selectedScene().nameLanguageKey,
      sceneSectionLanguageKey: this.dataStore.selectedScene().nameLanguageKey,
      actualSceneNameLanguageKey:
        this.sceneStore.reportUpload().sceneSectionLanguageKey,
      id: crypto.randomUUID(),
    };
  }

  submitData(reportContext: string): void {
    encryptReport(this.uploadObject, 'fxhToKHXW82SlxvdfrYsIXMak2RdmpLD').then(
      (encryptedUpload) => {
        this.http
          .post<{ [key: string]: string }>(
            this.baseUrl + `/api/user/reports/context/${reportContext}`,
            encryptedUpload.encrypted,
            {
              headers: {
                'Content-Type': 'application/json',
                apiKey: 'kpgwtlAF2WTawB3ZlcUUsmCIYZTL4E',
                iv: encryptedUpload.iv,
              },
            },
          )
          .pipe(catchError(() => EMPTY))
          .subscribe((response) => {
            this.sceneStore.screenshots().forEach((screenshot) => {
              this.uploadScreenshot(
                { ...screenshot, mimeType: 'image/png' },
                response[screenshot.id],
              );
            });
            this.sceneStore.videos().forEach((video) => {
              this.uploadBlobInChunks(video.blob, response[video.id]);
            });
          });
      },
    );
  }

  uploadScreenshot(file: FileUpload, url: string) {
    const headers = {
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.mimeType ?? 'image/png',
    };

    return this.http
      .put(url, file.blob, { headers })
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        this.chunkCount++;
        this.fileUploadProgress.set(
          100 *
            (this.chunkCount /
              (this.videoChunks.length +
                this.sceneStore.screenshots().length +
                1)),
        );
      });
  }

  uploadBlobInChunks(file: Blob, url: string) {
    const chunkSize = 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const chunks: Blob[] = [];

    for (let offset = 0; offset < file.size; offset += chunkSize) {
      const chunk = file.slice(offset, offset + chunkSize);
      chunks.push(chunk);
    }

    forkJoin(
      chunks.map((chunk, index) => {
        const blockId = btoa(createId(10));
        this.blockIds.push(blockId);
        const blockUrl = `${url}&comp=block&blockid=${blockId}`;
        return this.http.put(blockUrl, chunk, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type,
          },
        });
      }),
    )
      .pipe(
        tap(() => {
          this.chunkCount++;
          this.fileUploadProgress.set(
            100 *
              (this.chunkCount /
                (totalChunks + this.sceneStore.screenshots().length + 1)),
          );
        }),
      )
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        let requestBody = `<?xml version="1.0" encoding="utf-8"?>
              <BlockList>`;
        this.blockIds.forEach((id) => {
          requestBody = requestBody + `<Latest>${id}</Latest>`;
        });
        requestBody = requestBody + '</BlockList>';

        this.http
          .put(url + `&comp=blocklist`, requestBody, {
            headers: {
              'Content-Type': 'text/plain; charset=UTF-8',
            },
          })
          .pipe(catchError(() => EMPTY))
          .subscribe(() => {
            this.router.navigate(['/end'], {
              queryParamsHandling: 'preserve',
            });
          });
      });
  }
}
