import { Injectable, signal, inject } from '@angular/core';
import { AppStore } from '@stores';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  appStore = inject(AppStore);

  constructor() {
    fetch('unity-app/StreamingAssets/Language/Lang.en.json')
      .then((response) => response.json())
      .then((localization) => {
        this.localization.set({ en: localization });
      })
      .catch((error) => {
        console.error('Error loading config:', error);
      });
  }

  localization = signal<{ [key: string]: { [key: string]: string } }>({});

  getTranslation(key: string) {
    return this.localization()[this.appStore.appLanguage()][key] ?? key;
  }
}
