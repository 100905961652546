import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { ReportUpload } from '@api';
import { SceneReport, ScreenshotEntry, VideoEntry } from '@models';
import {
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { DataStore, UserStore } from '@stores';
import { LocalizationService } from '@services';

interface SceneState {
  report: ReportUpload;
  screenshots: ScreenshotEntry[];
  videos: VideoEntry[];
}

const initialState: SceneState = {
  report: {} as ReportUpload,
  screenshots: [],
  videos: [],
};

export const SceneStore = signalStore(
  { providedIn: 'root' },
  withDevtools('scene'),
  withState(initialState),
  withComputed(
    (
      { report, screenshots, videos },
      dataStore = inject(DataStore),
      userStore = inject(UserStore),
      localizationService = inject(LocalizationService),
    ) => ({
      localizedReport: computed((): SceneReport => {
        const scene = dataStore
          .sceneSections()
          .flatMap((sceneSection) => sceneSection.scenes)
          .find((scene) => scene.identifier === report().sceneIdentifier);
        return {
          data: {
            ...report(),
            name: localizationService.getTranslation(
              scene?.nameLanguageKey as string,
            ),
            sectionName: localizationService.getTranslation(
              scene?.sceneSectionNameLanguageKey as string,
            ),
          },
          user: userStore.fullName(),
        } as SceneReport;
      }),
      reportUpload: computed((): ReportUpload => {
        const screenshotsUpload = screenshots().map((screenshot) => {
          const { blob, ...screenshotUpload } = screenshot;
          return screenshotUpload;
        });
        const videosUpload = videos().map((video) => {
          const { blob, ...videoUpload } = video;
          return videoUpload;
        });
        return {
          ...report(),
          screenshots: screenshotsUpload,
          movies: videosUpload,
        };
      }),
    }),
  ),
  withMethods((store) => ({
    reset: () =>
      updateState(store, '[Scene] Reset', (state: SceneState) => initialState),
    setReport: (report: ReportUpload) =>
      updateState(store, '[Scene] Set report', (state: SceneState) => ({
        report,
      })),
    addScreenshot: (screenshot: ScreenshotEntry) =>
      updateState(store, '[Scene] Add screenshot', (state: SceneState) => ({
        screenshots: [...state.screenshots, screenshot],
      })),
    addVideo: (video: VideoEntry) =>
      updateState(store, '[Scene] Add video', (state: SceneState) => ({
        videos: [...state.videos, video],
      })),
  })),
);
